<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> My Profile</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col md="3" v-if="loading">
            <Navigation />
          </v-col>
          <v-col md="9" v-if="loading">
            <div class="filterationBox py-5">
              <Loading />
            </div>
          </v-col>
          <v-col md="3">
            <Navigation />
          </v-col>
          <v-col md="9" cols="12" v-if="!loading">
            <h2 class="mb-5">
              Ordercode : <strong>{{ order.orderCode }}</strong>
            </h2>
            <div class="filterationBox">
              <v-row class="py-3">
                <v-col md="6">
                  <div class="shadowBox">
                    <h2>Billing Address</h2>
                    <strong>{{ order.billingAddress.fullname }}</strong>
                    <p>
                      <span class="text-capitalize">{{
                        order.billingAddress.address
                      }}</span>
                      <span
                        class="text-capitalize"
                        v-if="order.billingAddress.ringRoad"
                        >, {{ order.billingAddress.ringRoad }}</span
                      >
                      <span
                        class="text-capitalize"
                        v-if="order.billingAddress.valley"
                        >, {{ order.billingAddress.valley }}</span
                      >
                      <span
                        class="text-capitalize"
                        v-if="order.billingAddress.province"
                        >, {{ order.billingAddress.province }}</span
                      >
                      <span>, Nepal</span>
                    </p>
                    <p>+977 {{ order.billingAddress.phone1 }}</p>
                    <p>
                      {{ order.billingAddress.email }}
                    </p>
                  </div>
                </v-col>
                <v-col md="6">
                  <div class="shadowBox">
                    <h2>Shipping Address</h2>
                    <strong>{{ order.billingAddress.fullname }}</strong>
                    <p>
                      <span class="text-capitalize">{{
                        order.billingAddress.address
                      }}</span>
                      <span
                        class="text-capitalize"
                        v-if="order.billingAddress.ringRoad"
                        >, {{ order.billingAddress.ringRoad }}</span
                      >
                      <span
                        class="text-capitalize"
                        v-if="order.billingAddress.valley"
                        >, {{ order.billingAddress.valley }}</span
                      >
                      <span
                        class="text-capitalize"
                        v-if="order.billingAddress.province"
                        >, {{ order.billingAddress.province }}</span
                      >
                      <span>, Nepal</span>
                    </p>
                    <p>+977 {{ order.billingAddress.phone1 }}</p>
                    <p>
                      {{ order.billingAddress.email }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" cols="12">
                  {{ order.orderCode }}
                </v-col>
                <v-col md="6" class="text-right" cols="12">
                  {{ order.orderStatus }}
                </v-col>
                <v-col md="12" cols="12">
                  <p>
                    <strong>Payment Method : </strong
                    >{{ order.details.paymentType }}
                  </p>
                  <p>
                    <strong>Transaction Code : </strong
                    >{{ order.details.transactionCode }}
                  </p>
                </v-col>
                <v-col md="6">{{ order.subOrderCode }}</v-col>
                <v-col md="6" class="text-right">{{
                  order.trakingOrder
                }}</v-col>
                <v-col md="12" cols="12">
                  <div
                    v-for="(data, index) in order.orders"
                    :key="index + 'order'"
                    class="productItem"
                  >
                    <div class="d-flex">
                      <img :src="data.image" alt="" />
                      <div>
                        <h1>{{ data.subOrderCode }}</h1>
                        <h1>{{ data.orderStatus }}</h1>
                        <h3>{{ data.productName }}</h3>
                        <p>Qty : {{ data.quantity }}</p>
                        <p>Rs. {{ data.price }}</p>
                      </div>
                    </div>

                    <div>
                      <p>
                        <strong>Place on : </strong
                        >{{
                          data.createdAt | moment("MMMM Do YYYY : hh :mm A")
                        }}
                      </p>
                      <p>
                        <strong>Delivery By : </strong
                        >{{
                          data.deliveryDate | moment("MMMM Do YYYY : hh :mm A")
                        }}
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col md="12">
                  <ul class="totalList">
                    <li>
                      <strong>SubTotal : </strong>Rs.
                      {{ order.details.subTotal }}
                    </li>
                    <li>
                      <strong>Shipping : </strong>Rs.
                      {{ order.details.shipping }}
                    </li>
                    <li>
                      <strong>Total : </strong>Rs. {{ order.details.total }}
                    </li>
                  </ul>
                </v-col>
                <v-col md="12" v-if="order.orderStatus == 'Cancelled'">
                  <hr class="mb-5" />
                  <div>
                    <h3>Reason for cancellation</h3>
                    <p><strong>Reason : </strong>{{ order.reason }}</p>
                    <p>{{ order.description }}</p>
                  </div>
                </v-col>
                <v-col md="12" v-if="!loadingBtn">
                  <ul class="actBtn">
                    <li v-if="order.orderStatus == 'Pending'">
                      <v-btn class="cancelBtn" @click="popAction"
                        >Cancel Order</v-btn
                      >
                    </li>
                    <li v-if="order.orderStatus == 'Completed'">
                      <v-btn class="acceptBtn" @click="popAction"
                        >Exchange</v-btn
                      >
                    </li>
                    <li v-if="order.orderStatus == 'Completed'">
                      <v-btn class="acceptBtn" @click="popAction">Return</v-btn>
                    </li>
                  </ul>
                  <ul class="actBtn" v-if="loadingBtn">
                    <li>
                      <v-btn class="acceptBtn" loading></v-btn>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </div>
            <div class="filterationBox" v-if="loading">
              <Loading />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="popForm" v-if="popStatus">
        <div class="formBox">
          <div class="d-flex align-center justify-space-between">
            <h3 class="mb-3">Reason Form</h3>
            <v-icon class="mb-3" @click="popAction"> mdi-close </v-icon>
          </div>

          <v-select
            placeholder="Reason"
            :items="allReasons.results"
            item-text="reason"
            item-value="reason"
            v-model="reason"
            outlined
          ></v-select>
          <v-textarea
            rows="5"
            placeholder="Description"
            outlined
            v-model="description"
          ></v-textarea>
          <v-btn
            class="cancelBtn"
            v-if="!loadingBtn"
            @click="orderAction('Cancelled')"
            >Cancel</v-btn
          >
          <v-btn class="acceptBtn" v-if="loadingBtn" loading></v-btn>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Navigation from "@/components/user/Navigation";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SingleOrder",
  data: () => ({
    limit: 10,
    page: 1,
    order: undefined,
    loading: true,
    reasons: [],
    description: undefined,
    reason: undefined,
    popStatus: false,
    loadingBtn: false,
  }),
  components: {
    Loading,
    Navigation,
  },
  methods: {
    ...mapActions(["getOrders", "getOrder", "putOrder", "getReasons"]),
    popAction() {
      this.popStatus = !this.popStatus;
    },
    async orderAction(action) {
      this.loadingBtn = true;
      let filter = { orderStatus: action, id: this.order._id };
      if (action == "Cancelled") {
        filter.reason = this.reason;
        filter.description = this.description;
      }
      await this.putOrder(filter);
      this.order = this.allOrder.results;
      this.loadingBtn = false;
      this.popStatus = false;
    },
  },
  computed: {
    ...mapGetters(["allOrders", "allOrder", "allReasons"]),
  },
  async created() {
    await this.getOrder(this.$route.params.id);
    this.order = this.allOrder.results;
    if (this.order.orderStatus == "Pending") {
      await this.getReasons("cancelOrder");
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.totalList {
  list-style: none;
  padding-left: 0;
}
.shadowBox {
  box-shadow: 0 0 5px #d0cece;
  padding: 15px 15px 10px;
  border-radius: 10px;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.productItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  img {
    width: 120px;
    border: 1px solid #efefef;
    margin-right: 20px;
    object-fit: cover;
  }
}
.totalList {
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
}
.v-btn {
  min-height: 5 0px;
  width: 200px;
  color: #0878d4 !important;
  background: #fff !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}
.filterationBox {
  h2 {
    font-size: 20px !important;
    margin-bottom: 8px;
  }
  border: 1px solid #efefef;
  padding: 10px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 15px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .detailList {
    padding-left: 0;
    margin-bottom: 10px;
    li {
      color: #8c8c8c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      .v-icon {
        margin-left: 10px;
        font-size: 20px;
      }
    }
  }
}
.v-input {
  margin-top: 0 !important;
}
a {
  text-decoration: none;
}
p {
  margin-bottom: 3px;
}
.actBtn {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  li {
    &:last-of-type {
      margin-left: 15px;
    }
  }
}
.acceptBtn {
  background: #0878d4 !important;
  color: #fff !important;
  min-height: 50px;
}
.cancelBtn {
  background: #d41908 !important;
  color: #fff !important;
  border-color: #d41908 !important;
  min-height: 50px;
}
.orders {
  display: flex;
  padding-left: 0;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 -15px;
  li {
    width: 50%;
    padding: 5px 10px;
    .offerCard {
      box-shadow: 0 0 5px #e0dddd;
      border-radius: 10px;
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      .image {
        width: 100px;
        height: 100px;
        background: #f4f4f4;
        img {
          object-fit: contain;
          width: 100px;
          height: 100px;
          border-radius: 10px;
        }
        margin-right: 10px;
      }
      .offerDescription {
        width: 75%;
        padding-left: 15px;
        .offerTitle {
          color: rgba(26, 26, 26, 1);
          font-weight: 600;
          font-size: 20px;
        }
        .price {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          p {
            font-size: 16px;
          }
        }
      }
    }
    .text-red {
      color: rgba(197, 46, 127, 1);
      font-weight: 700;
    }
    .text-yellow {
      color: #f9be27 !important;
      font-size: 18px;
    }
    .salePrice {
      position: relative;
      text-decoration: line-through;
      &::after {
        position: absolute;
        text-decoration: none;
      }
    }
  }
}
.popForm {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 99999;
  .formBox {
    box-shadow: 0 0 5px #efefef;
    padding: 20px;
    margin: 50px auto 0;
    background: #fff;
    width: 500px;
  }
}
</style>